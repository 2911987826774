<template>
  <div
    :class="[
      'action-input',
      { 'action-input--no-hover': noHover },
    ]"
    :text="message"
  >
    <TextInput
      class="action-input__field"
      :model-value="value"
      :small="small"
      no-clear-button
      readonly
      @click="activate"
    />

    <Icon
      v-if="icon"
      class="action-input__icon"
      :name="icon"
      @click="activate"
    />

    <div
      v-if="message"
      v-show="active"
      :class="['action-input__tooltip', position]"
    >
      {{ message }}
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import TextInput from './TextInput';
import Icon from './Icon';

export default {
  components: {
    TextInput,
    Icon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
    noHover: {
      type: Boolean,
      default: false,
    },
    position: {
      required: false,
      type: String,
      default: 'right',
    },
  },
  emits: {
    click: {},
  },
  setup(props, { emit }) {
    const active = ref(false);
    const activeTimeout = ref(null);
    const reset = () => {
      active.value = false;
      if (!activeTimeout.value) return;
      clearTimeout(activeTimeout.value);
      activeTimeout.value = null;
    };
    const activate = () => {
      if (active.value) return;
      activeTimeout.value = setTimeout(reset, 3000);
      active.value = true;
      emit('click');
    };

    return {
      active,
      activate,
    };
  },
};
</script>

<style lang="scss">
.action-input {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  .action-input__field {
    width: 100%;

    input {
      padding-right: 38px;
      cursor: pointer;
      user-select: none;

      &:hover {
        background: #FAFAFA;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  &--no-hover {
    .action-input__field {
      width: 100%;

      input {
        cursor: default;

        &:hover {
          background: inherit;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .action-input__icon {
    position: absolute;
    stroke: #000;
    top: 50%;
    right: 12px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .action-input__tooltip {
    display: flex;
    align-items: center;
    position: absolute;
    left: 98%;
    background-color: $gray400;
    color: $black;
    white-space: nowrap;
    padding: 0 8px;
    height: 30px;
    border-radius: 8px;
    cursor: default;
    pointer-events: none;
    z-index: 100;

    &.left {
      left: unset;
      right: 98%;
    }
  }
}
</style>
