<template>
  <div
    :class="[
      'button-group',
      { 'button-group--small': small },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.button-group {
  display: flex;
  gap: 16px;

  &.button-group--small {
    gap: 5px;
  }
}
</style>
