<template>
  <div
    :class="[
      'chip',
      `chip--${variant}`,
      { 'chip--small': small },
    ]"
  >
    <slot />
  </div>
</template>

<script>
import { includes } from 'lodash';

export default {
  props: {
    variant: {
      type: String,
      default: 'default',
      validator: (value) => includes(['default', 'white', 'blue', 'green'], value),
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  display: flex;
  align-items: center;
  background-color: $chipBackgroundColor;
  color: $chipTextColor;
  border: $chipBorder;
  border-radius: $chipBorderRadius;
  padding: $chipPadding;
  user-select: none;
  height: 25px;
  white-space: nowrap;

  &.chip--small {
    font-size: 10px;
    height: 20px;
    text-transform: uppercase;
  }

  &.chip--white {
    background-color: $chipWhiteBackgroundColor;
    border-color: $chipWhiteBorderColor;
  }

  &.chip--blue {
    background-color: $chipBlueBackgroundColor;
    border-color: $chipBlueBorderColor;
  }

  &.chip--green {
    background-color: $chipGreenBackgroundColor;
    border-color: $chipGreenBorderColor;
  }
}
</style>
