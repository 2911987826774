<template>
  <div class="baseball-indicators">
    <div class="baseball-indicators__box">
      <div class="baseball-indicators__label">
        Ball
      </div>
      <div class="baseball-indicators__indicators">
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': balls >= 1 },
          ]"
        />
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': balls >= 2 },
          ]"
        />
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': balls >= 3 },
          ]"
        />
      </div>
    </div>
    <div class="baseball-indicators__box">
      <div class="baseball-indicators__label">
        Strike
      </div>
      <div class="baseball-indicators__indicators">
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': strikes >= 1 },
          ]"
        />
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': strikes >= 2 },
          ]"
        />
      </div>
    </div>
    <div class="baseball-indicators__box">
      <div class="baseball-indicators__label">
        Out
      </div>
      <div class="baseball-indicators__indicators">
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': outs >= 1 },
          ]"
        />
        <div
          :class="[
            'baseball-indicators__circle',
            { 'baseball-indicators__circle--is-filled': outs >= 2 },
          ]"
        />
      </div>
    </div>
    <div class="baseball-indicators__bases">
      <div
        :class="[
          'baseball-indicators__base',
          'baseball-indicators__base--is-first',
          { 'baseball-indicators__base--is-filled': base3 },
        ]"
      />
      <div
        :class="[
          'baseball-indicators__base',
          'baseball-indicators__base--is-second',
          { 'baseball-indicators__base--is-filled': base2 },
        ]"
      />
      <div
        :class="[
          'baseball-indicators__base',
          'baseball-indicators__base--is-third',
          { 'baseball-indicators__base--is-filled': base1 },
        ]"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    balls: {
      type: Number,
      default: 0,
    },
    strikes: {
      type: Number,
      default: 0,
    },
    outs: {
      type: Number,
      default: 0,
    },
    base1: {
      type: Boolean,
      default: false,
    },
    base2: {
      type: Boolean,
      default: false,
    },
    base3: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.baseball-indicators {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 23.24px;
  gap: 32px;
  padding-bottom: 20px;

  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &__label {
    font-family: 'Rubik', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.22px;
  }

  &__indicators {
    display: flex;
    gap: 4px;
  }

  &__circle {
    background-color: #fff;
    border: 2px solid #A9A9A9;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    user-select: none;

    &--is-filled {
      background-color: #000;
      border-color: #000;
    }
  }

  &__bases {
    position: relative;
    width: 32.75px;
    height: 23.24px;
  }

  &__base {
    border: 2px solid #A9A9A9;
    user-select: none;
    position: absolute;
    width: 10.46px;
    height: 10.46px;
    transform: rotate(45deg);

    &--is-first {
      bottom: 2px;
      left: 4px;
    }

    &--is-second {
      top: 6px;
      left: 50%;
      transform: rotate(45deg) translateX(-50%);
    }

    &--is-third {
      bottom: 2px;
      right: 1px;
    }

    &--is-filled {
      background-color: #000;
      border-color: #000;
    }
  }
}
</style>
