<template>
  <div
    :class="[
      'checkbox',
      { 'checkbox--checked': intermediate || modelValue },
      { 'checkbox--disabled': disabled },
      { 'checkbox--round': round },
    ]"
    @click.stop="toggle"
    :tabindex="tabIndex"
  >
    <Icon
      v-show="intermediate || modelValue"
      class="checkbox-icon"
      :name="intermediate ? 'minus' : 'check'"
    />
  </div>
</template>

<script>
import Icon from './Icon';

export default {
  components: {
    Icon,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    intermediate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    tabIndex: {
      type: Number,
      default: 0,
    },
  },
  emits: {
    'update:modelValue': {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const toggle = () => {
      if (props.disabled) return;
      emit('update:modelValue', !props.modelValue);
    };

    return {
      toggle,
    };
  },
};
</script>

<style lang="scss">
.checkbox {
  display: flex;
  background-color: #FFFFFF;
  border: 2px solid #CDCDCD;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 16px;
  max-width: 16px;
  width: 16px;
  min-height: 16px;
  max-width: 16px;
  height: 16px;

  cursor: pointer;
  transition: $componentBoxShadowTransition;
  aspect-ratio: 1 / 1;

  &.checkbox--disabled {
    cursor: default;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  &.checkbox--round {
    border-radius: 100%;
  }

  &:focus {
    box-shadow: 0 0 0 2px $brandPrimary300;
    outline: none;
  }

  .checkbox-icon {
    stroke: $white;
    width: 12px;
    height: 12px;
  }
}

.checkbox.checkbox--checked {
  background-color: $brandPrimary500;
  border-color: $brandPrimary500;
}

.checkbox.checkbox--disabled {
  opacity: 0.4;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
</style>
