<template>
  <div
    :class="[
      'baseball-clock',
      { 'baseball-clock--is-active': !!teamInPossession }
    ]"
  >
    <Icon
      :class="[
        'baseball-clock__icon',
        `baseball-clock__icon--is-${icon}`
      ]"
      :name="icon"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    side: {
      type: String,
      required: true,
    },
    teamInPossession: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const icon = computed(() => {
      if (!props.teamInPossession || props.teamInPossession === 'NEUTRAL') return 'baseball';
      if (props.side === props.teamInPossession) return 'hitter';
      return 'pitcher';
    });

    return {
      icon,
    };
  },
};
</script>

<style lang="scss">
.baseball-clock {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  min-width: 28px;
  height: 28px;
  min-height: 28px;
  padding: 4px;
  border-radius: 100%;
  position: relative;
  background-color: #E5EBEB;
  opacity: 0.4000000059604645;
  overflow: hidden;

  &--is-active {
    opacity: 1;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 57%;
    height: 57%;
    transform: translate(-50%, -50%);

    &--is-pitcher {
      svg {
        g {
          path {
            stroke: #003C3C;
          }
        }
      }
    }

    &--is-hitter {
      svg {
        path {
          fill: #003C3C;
        }
      }
    }
  }
}
</style>
