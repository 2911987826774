<template>
  <div
    :class="[
      'boolean-indicator',
      { 'boolean-indicator--variant-success': isSuccessVariant },
      { 'boolean-indicator--variant-danger': isDangerVariant },
    ]"
  >
    <Icon :name="iconName" />
  </div>
</template>

<script>
import { computed } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isSuccessVariant = computed(() => !!props.value);
    const isDangerVariant = computed(() => !props.value);
    const iconName = computed(() => (props.value ? 'check' : 'x'));

    return {
      isSuccessVariant,
      isDangerVariant,
      iconName,
    };
  },
};
</script>

<style lang="scss">
.boolean-indicator {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 16px;
  height: 16px;
  border-radius: 100%;

  .icon {
    width: 100%;
    height: 100%;
    padding: 2px;
  }

  &--variant-success {
    background: #00BC57;

    .icon {
      stroke: #fff;
    }
  }

  &--variant-danger {
    background: #FF2E2D;

    .icon {
      stroke: #fff;
    }
  }
}
</style>
